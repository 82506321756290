<template>
  <div>
    <el-page-header @back="back" content="订单详情"> </el-page-header>
    <el-descriptions border style="margin-top: 20px">
      <el-descriptions-item label="商品ID">{{ orderData.spuId }}</el-descriptions-item>
      <el-descriptions-item label="SKUID">{{ orderData.skuId }}</el-descriptions-item>
      <el-descriptions-item label="商品名称">{{
        orderData.spuName
      }}</el-descriptions-item>
      <el-descriptions-item label="商品封面">
        <el-image :src="orderData.logoUrl" fit="contain" style="width: 120px"></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="订单金额"
        >￥{{ orderData.totalAmount / 100 }}</el-descriptions-item
      >
      <el-descriptions-item v-if="orderData.paidAmount" label="支付金额"
        >￥{{ orderData.paidAmount / 100 }}</el-descriptions-item
      >
      <el-descriptions-item label="订单创建时间">{{
        formatTime(orderData.createTime)
      }}</el-descriptions-item>
      <el-descriptions-item v-if="orderData.payTime" label="订单支付时间">{{
        formatTime(orderData.payTime)
      }}</el-descriptions-item>
      <el-descriptions-item label="订单状态">{{
        orderData.statusDes
      }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      v-if="orderData.buyer"
      border
      title="买家信息"
      style="margin-top: 20px"
    >
      <el-descriptions-item label="用户ID">{{ orderData.buyer.id }}</el-descriptions-item>
      <el-descriptions-item label="用户ID">
        <el-avatar :src="orderData.buyer.avatar" :size="60" />
      </el-descriptions-item>
      <el-descriptions-item label="联系方式">{{
        orderData.buyer.phone
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import OrderApi from "../../request/OrderApi";
import dayjs from "dayjs";
export default {
  data() {
    return {
      orderId: null,
      orderData: {},
    };
  },
  mounted() {
    this.orderId = this.$route.query.id;
    this.loadData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    loadData() {
      OrderApi.detail({
        orderId: this.orderId,
      }).then((res) => {
        if (res.success) {
          this.orderData = res.data;
        }
      });
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style></style>
