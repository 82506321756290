const baseUrl = "/zp-transaction/v1/order/"
const baseWxUrl = '/zp-transaction/v1/mini/shop/order/'
import http from './http';
class OrderApi {
  static list(param) {
    return http.post(baseUrl + "list", param)
  }
  static detail(param) {
    return http.post(baseUrl + "detail", param)
  }

  // 微信视频号小店订单列表
  static wxOrderList(param) {
    return http.post(baseWxUrl + 'list', param);
  }



  // 微信视频号小店订单发布
  static wxOrderShop(param) {
    return http.post(baseWxUrl + 'ship', param);
  }

}
export default OrderApi;
